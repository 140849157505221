<template>
  <v-sheet
    elevation="3"
    rounded
    class="mb-4"
  >
    <v-container
      fluid
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <ProductSelector
            v-model="filters['product']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-text-field
            v-model="filters['email']"
            :label="$t('views.price_negotiations.forms.labels.email')"
            hide-details
            prepend-inner-icon="mdi-at"
            clearable
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters['status']"
            :label="$t('views.price_negotiations.forms.labels.status')"
            :items="values.status"
            prepend-inner-icon="mdi-state-machine"
            hide-details
            item-text="label"
            item-value="value"
            clearable
            outlined
            dense
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <StoreSelector
            v-model="filters['store_id']"
            filter
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <v-select
            v-model="filters['auto']"
            :label="$t('views.price_negotiations.forms.labels.auto')"
            :items="values.auto"
            hide-details
            prepend-inner-icon="mdi-auto-mode"
            item-text="label"
            item-value="value"
            clearable
            outlined
            dense
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import { debounce } from '@/helpers/debounce'
import ProductSelector from '@/components/base/Product/Input/ProductSelector.vue'
import StoreSelector from '@/components/base/StoreSelector.vue'

export default {
  name: 'PriceNegotiationFilters',
  components: { StoreSelector, ProductSelector },
  data() {
    return {
      filters: {
        product : this.$route.query['product'],
        status: this.$route.query['status'],
        store_id : this.$route.query['store_id'],
        email : this.$route.query['email'],
        auto : this.$route.query['auto'],
      },
      values: {
        status: [
          {
            label: this.$i18n.t('views.price_negotiations.forms.enum.status.pending'),
            value:'PENDING',
          },
          {
            label: this.$i18n.t('views.price_negotiations.forms.enum.status.accepted'),
            value:'ACCEPTED',
          },
          {
            label: this.$i18n.t('views.price_negotiations.forms.enum.status.rejected'),
            value:'REJECTED',
          },
          {
            label: this.$i18n.t('views.price_negotiations.forms.enum.status.used'),
            value:'USED',
          },
          {
            label: this.$i18n.t('views.price_negotiations.forms.enum.status.archived'),
            value:'ARCHIVED',
          },
        ],
        auto: [
          {
            label: this.$i18n.t('views.price_negotiations.forms.enum.auto.true'),
            value: 1,
          },
          {
            label: this.$i18n.t('views.price_negotiations.forms.enum.auto.false'),
            value: 0,
          },
        ],
      },
    }
  },
  watch: {
    filters: {
      handler: debounce(function (value) {
        this.$emit('onFiltersChanged', value)
      }, 500),
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
